<template>
	<div class="term-controls">
		<v2-button
			fit
			size="xs"
			class="edit-btn"
			@click="handleEdit">
			Edit
		</v2-button>
		<v2-button
			fit
			size="xs"
			variant="red"
			class="delete-btn"
			@click="handleDelete">
			Delete
		</v2-button>
		<portal
			to="modal"
			v-if="isEditOpen"
		>
			<edit-term-modal
				:term="term"
				:focus-options="options.focus"
				@close="closeEdit"
				@success="handleEditTerm" />
		</portal>
	</div>
</template>

<script>

	import V2Button from '@/components/v2/ui/V2Button.vue';
	import api from '@/services/api';
	import EditTermModal from './EditTermModal.vue';

	export default {
		components: {
			EditTermModal,
			V2Button
		},
		props: {
			term: {
				type: Object,
				default: undefined
			}
		},
		inject: [
			'options',
			'handleEditTerm',
			'handleDeleteTerm'
		],
		data: () => {
			return {
				isEditOpen: false
			};
		},
		computed: {
			getId () {
				return this.term.id;
			}
		},
		created () {},
		methods: {
			handleEdit () {
				this.isEditOpen = true;
			},
			closeEdit () {
				this.isEditOpen = false;
			},
			async handleDelete () {
				if (confirm('Are you sure?')) {
					const response = await api.glossary.deleteTerm({
						termId: this.getId
					});
					if (!response) {
						return false;
					}
					this.$store.commit('ui/showNotification', {
						notification: 'Term has been deleted'
					});
					this.handleDeleteTerm();
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

		.term-controls {
			display: flex;
			align-items: center;
			gap: 10px;
		}

</style>
