<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title glossary-title">
				<span>Glossary</span>
				<create-term-modal
					v-if="getIsAdmin"
					:focus-options="focus"
					@success="handleCreatedTerm"
				/>
			</div>
			<div class="page__info--subtitle">
				Use this glossary to find terms and definitions
			</div>
		</div>
		<div class="separator" />
		<div class="content">
			<div class="side">
				<glossary-filters
					:filters="filters"
					:search="searchString"
					:beginner="beginner"
					@update-search="debounceOnSearch"
					@update-filter="onFilter"
					@toggle-beginner="toggleBeginner"
				/>
				<alphabetical-navigation :groups="getGroups" />
			</div>
			<div class="main">
				<terms-list
					:terms="getNumericalTerms"
					:groups="getGroups"
				/>
				<back-to-top-button external-handler @click.native="resetAnchor" />
			</div>
		</div>
	</div>
</template>

<route>
{
	"meta": {
		"isPublic": true
	}
}
</route>

<script>

	import GlossaryFilters from '@/components/glossary/GlossaryFilters.vue';
	import CreateTermModal from '@/components/glossary/CreateTermModal.vue';
	import TermsList from '@/components/glossary/TermsList.vue';
	import AlphabeticalNavigation from '@/components/glossary/AlphabeticalNavigation.vue';
	import BackToTopButton from '@/components/glossary/BackToTopButton.vue';
	import api from '@/services/api';
	import { scrollTop, scrollTo } from '@/helpers';
	import { debounce } from 'lodash';

	export default {
		metaInfo: {
			title: 'Glossary'
		},
		layout: 'v2default',
		components: {
			BackToTopButton,
			AlphabeticalNavigation,
			CreateTermModal,
			GlossaryFilters,
			TermsList
		},
		data: () => ({
			terms: [],
			focus: [],
			allKeywords: [],
			searchString: '',
			beginner: false,
			filters:[],
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		provide () {
			const options = {};

			Object.defineProperty(options, 'focus', {
				enumerable: true,
				get: () => this.focus
			});

			return {
				options,
				handleEditTerm: this.handleEditTerm,
				handleDeleteTerm: this.handleDeleteTerm
			};
		},
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/glossary',
						text: 'Glossary'
					}
				].filter(Boolean);
			},
			getIsAdmin () {
				return this.$store.getters['auth/getIsAdmin'];
			},
			getNumericalTerms () {
				return this.terms.filter((term) => {
					const firstLetter = term.name[0].toLowerCase();
					return !isNaN(firstLetter);
				});
			},
			getGroups () {
				const groupsMap = this.terms.filter((term) => {
					const firstLetter = term.name[0].toLowerCase();
					return isNaN(firstLetter);
				}).reduce((acc, term) => {
					const firstLetter = term.name[0].toLowerCase();
					return { ...acc, [firstLetter]: [...(acc[firstLetter] || []), term] };
				}, {});

				return Object.entries(groupsMap).map(([key, items]) => {
					return {
						key,
						items,
						title: key
					};
				});
			}
		},
		async created () {
			await this.setTerms();
		},
		methods: {
			setAnchor (hash) {
				if (hash) {
					this.$router.replace({ hash });
				}
			},
			resetAnchor () {
				const hash = this.$route.hash;
				if (hash) {
					this.$router.replace({ hash: null });
				}
				scrollTop();
			},
			scrollToPageAnchor () {
				const hash = this.$route.hash;
				if (hash) {
					scrollTo(hash, 20);
				}
			},
			onSearch (value) {
				this.searchString = value;
				this.pagination.currentPage = 1;
				this.setTerms();
				this.resetAnchor();
			},
			toggleBeginner () {
				this.beginner = !this.beginner;
				this.setTerms();
			},
			debounceOnSearch:debounce(function (value) {
				this.onSearch(value);
			}, 500),
			onFilter () {
				this.pagination.currentPage = 1;
				this.setTerms();
				this.resetAnchor();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setTerms();
			},
			async setTerms () {
				const response = await api.glossary.getGlossary(this.getFilterData());
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.focus = response.meta.focuses;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.terms = response.results;

				this.setFilters(response.meta.filter);

				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});

				this.$store.commit('ui/setLoadingIsHidden');

				this.$nextTick(() => {
					this.scrollToPageAnchor();
				});
			},
			getSkillFocusList () {
				return [
					...this.focus.map((item) => ({ label: item.focus, id: item.focus }))
				];
			},
			setFilters (filter) {

				const getSelected = (filterTitle) => {
					const selectedValue = this.filters?.find(({ title }) => title === filterTitle)?.selected;

					return selectedValue || [];
				};

				this.filters = [
					{
						type: 'Checkbox',
						title: 'Skill focus',
						values: this.getSkillFocusList(),
						selected: getSelected('Skill focus')
					}
				];
			},
			getFilterData () {
				if (!this.filters.length) {
					return {
						include: 'metadata,focus_keyword,only_linked_keywords',
						beginner: this.beginner,
						page: this.pagination.currentPage,
						per: this.pagination.perPage,
						sortBy: '',
						sortDir: 'ASC'
					};
				}

				const getFilterValue = (filterName) => {
					return this.filters.find(({ title }) => title === filterName).selected.filter((item) => item !== -1);
				};

				return {
					include: 'metadata,focus_keyword,only_linked_keywords',
					searchString: this.searchString,
					beginner: this.beginner,
					filters: {
						focuses: getFilterValue('Skill focus')
					},
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: '',
					sortDir: 'ASC'
				};
			},
			async handleCreatedTerm (term) {
				if (term) {
					this.setAnchor(term.slug);
					await this.setTerms();
				}
			},
			async handleEditTerm (term) {
				if (term) {
					this.setAnchor(term.slug);
					await this.setTerms();
				}
			},
			async handleDeleteTerm () {
				await this.setTerms();
			}
		}
	};

</script>

<style lang="scss" scoped>

	.page {
		.glossary-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.content {
		.side {
			position: sticky;
			top: 20px;
			height: fit-content;
			display: flex;
			flex-direction: column;
			gap: 20px;

			.scroll-top-btn {
				margin: auto;
			}
		}

		@media (max-width: 1279px) {
			display: flex;
			flex-direction: column;

			.side {
				width: 100%;
				position: static;
				gap: 0;

				.scroll-top-btn {
					display: none;
				}
			}

			.main {
				width: 100%;
			}
		}
	}

</style>
