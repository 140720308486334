<template>
	<modal-inner
		@close="handleClose"
		heading="Edit term"
	>
		<glossary-term-form
			v-model="termData"
			ref="form"
			:focus-options="focusOptions"
		/>
		<actions
			:actions="getActions"
			:isWaiting="isWaiting"
			@actionClick="onActionClick"
		/>
	</modal-inner>
</template>

<script>
	import ModalInner from '@/components/ui/modal/ModalInner';
	import GlossaryTermForm from '@/components/glossary/GlossaryTermForm.vue';
	import Actions from '@/components/ui/Actions.vue';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import api from '@/services/api';

	export default {
		components: {
			Actions,
			ModalInner,
			GlossaryTermForm
		},
		mixins: [actionClick, invalidFormMessage],
		inject: [],
		props: {
			term: {
				type: Object,
				default: undefined
			},
			focusOptions: {
				type: Array,
				default: () => ([])
			}
		},
		data () {
			return {
				termData: {
					name: '',
					definition: '',
					focuses: [],
					beginner: false
				},
				isWaiting: undefined,
				isOpen: false
			};
		},
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'handleSave'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleClose'
						}
					]
				};
			}
		},
		created () {
			if (this.term) {
				this.termData = JSON.parse(JSON.stringify(this.term));
			}
		},
		methods: {
			handleClose () {
				this.$emit('close');
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();

				if (!valid) {
					this.showInvalidFormMessage();
				}

				this.isWaiting = 'handleSave';
				const response = await api.glossary.editTerm({
					termId: this.term.id,
					term: this.termData
				});
				if (response) {
					this.$store.commit('ui/showNotification', {
						notification: 'Updated successfully.'
					});
					this.$emit('success', response);
					this.handleClose();
				}
				this.isWaiting = undefined;
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
