<template>
	<div data-component="create-term-modal">
		<v2-button fit @click="handleOpen">
			Add term
		</v2-button>
		<portal
			to="modal"
			v-if="isOpen"
		>
			<modal-inner
				@close="handleClose"
				heading="Add term"
			>
				<glossary-term-form
					v-model="term"
					ref="form"
					:focus-options="focusOptions"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>
	import V2Button from '@/components/v2/ui/V2Button';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import GlossaryTermForm from '@/components/glossary/GlossaryTermForm.vue';
	import Actions from '@/components/ui/Actions.vue';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import api from '@/services/api';

	export default {
		components: {
			Actions,
			V2Button,
			ModalInner,
			GlossaryTermForm
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			focusOptions: {
				type: Array,
				default: () => ([])
			}
		},
		data () {
			return {
				term: {
					name: '',
					definition: '',
					focuses: [],
					beginner: false
				},
				isWaiting: undefined,
				isOpen: false
			};
		},
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'handleSave'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleClose'
						}
					]
				};
			}
		},
		watch: {
			isOpen () {
				this.resetData();
			}
		},
		created () {},
		methods: {
			resetData () {
				this.term = {
					name: '',
					definition: '',
					focuses: [],
					beginner: false
				};
			},
			handleOpen () {
				this.isOpen = true;
			},
			handleClose () {
				this.isOpen = false;
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();

				if (!valid) {
					this.showInvalidFormMessage();
				}

				this.isWaiting = 'handleSave';
				const response = await api.glossary.createTerm({ term: this.term });
				if (response) {
					this.$store.commit('ui/showNotification', {
						notification: 'Saved successfully.'
					});
					this.$emit('success', response);
					this.handleClose();
				}
				this.isWaiting = undefined;
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
