<template>
	<div class="terms-group" :id="getId">
		<h1 class="group-title">
			<router-link :to="getAnchor">
				{{ getTitle }}
			</router-link>
		</h1>
		<div
			class="terms-list"
		>
			<term
				v-for="(term, key) in getTerms"
				:key="key"
				:term="term"
			/>
		</div>
	</div>
</template>

<script>
	import Term from '@/components/glossary/Term.vue';

	export default {
		components: {
			Term
		},
		props: {
			group: {
				type: Object,
				default: undefined
			}
		},
		data: () => {
			return {

			};
		},
		computed: {
			getId () {
				return this.group.key;
			},
			getAnchor () {
				return {
					hash: `#${this.getId}`
				};
			},
			getTitle () {
				return this.group.title;
			},
			getTerms () {
				return this.group.items;
			}
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>

	.terms-group {
		margin-bottom: 34px;

		.group-title {
			@include font(32px, $c-medium-navy, bold, 38px);
			text-transform: uppercase;
			margin-bottom: 32px;
		}

		.terms-list {
			padding-left: 10px;
		}
	}

</style>
