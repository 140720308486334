<template>
	<validation-observer
		ref="form"
		data-element="form"
		tag="form"
	>
		<input-field
			name="Term"
			type="text"
			rules="required"
			:value="getName"
			@input="changeName"
		/>
		<wysiwyg-field
			name="Definition"
			type="text"
			rules="required"
			:value="getDefinition"
			@input="changeDefinition"
		/>
		<select-field
			name="Skill focus"
			multiple
			:options="getFocusOptions"
			:value="getFocuses"
			@input="changeFocuses"
		/>
		<v2-checkbox-item
			class="beginner-checkbox"
			:value="getBeginner"
			:selected="getBeginner"
			@change="changeBeginner">
			For beginners
		</v2-checkbox-item>
	</validation-observer>
</template>

<script>

	import InputField from '@/components/forms/InputField';
	import SelectField from '@/components/forms/SelectField';
	import V2CheckboxItem from '@/components/v2/ui/checkbox/V2CheckboxItem';

	export default {
		components: {
			InputField,
			SelectField,
			V2CheckboxItem,
			WysiwygField: () => import('@/components/forms/WysiwygField')
		},
		props: {
			value: {
				type: Object,
				default: () => ({
					name: '',
					definition: '',
					focuses: [],
					beginner: false
				})
			},
			focusOptions: {
				type: Array,
				default: () => ([])
			},
			isWaiting: {
				type: String,
				default: undefined
			}
		},
		data () {
			return {};
		},
		computed: {
			getName () {
				return this.value?.name || '';
			},
			getDefinition () {
				return this.value?.definition || '';
			},
			getFocuses () {
				return this.value?.focuses || [];
			},
			getFocusOptions () {
				return this.focusOptions.map((focus) => {
					return {
						text: focus.focus,
						value: focus.focus
					};
				});
			},
			getBeginner () {
				return this.value?.beginner || false;
			}
		},
		created () {},
		methods: {
			changeName (name) {
				this.$emit('input', {
					...this.value,
					name
				});
			},
			changeDefinition (definition) {
				this.$emit('input', {
					...this.value,
					definition
				});
			},
			changeFocuses (focuses) {
				this.$emit('input', {
					...this.value,
					focuses
				});
			},
			changeBeginner () {
				this.$emit('input', {
					...this.value,
					beginner: !this.value.beginner
				});
			},
			validate () {
				return this.$refs.form.validate();
			},
			resetForm () {
				this.$emit('input', {
					name: '',
					definition: '',
					focuses: [],
					beginner: false
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-element='form'] {
		margin-bottom: 0;

		[data-component='input-field'] {
			margin: 0;
		}

		[data-component='wysiwyg-field'] {
			margin-bottom: 0;
		}
	}

</style>
