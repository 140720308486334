<template>
	<div class="terms-list-container">
		<div
			v-if="terms.length"
			class="non-grouped-terms-list"
		>
			<term
				v-for="(term, key) in terms"
				:key="key"
				:term="term"
			/>
		</div>
		<div
			class="groups-list"
		>
			<terms-group
				v-for="group in groups"
				:key="group.key"
				:group="group"
			/>
		</div>
	</div>
</template>

<script>
	import Term from '@/components/glossary/Term.vue';
	import TermsGroup from '@/components/glossary/TermsGroup.vue';

	export default {
		components: {
			Term,
			TermsGroup
		},
		props: {
			terms: {
				type: Array,
				default: () => []
			},
			groups: {
				type: Array,
				default: () => []
			}
		},
		data: () => {
			return {

			};
		},
		computed: {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	.non-grouped-terms-list {
		margin: 2px 0;
		padding-left: 10px;
	}

</style>
