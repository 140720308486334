<template>
	<div class="term-item" :id="getId">
		<div class="term-header">
			<router-link :to="getAnchor">
				<h2 class="term-name">
					{{ getName }}
				</h2>
			</router-link>
			<term-controls v-if="getIsAdmin" :term="term" />
		</div>
		<p class="term-definition" v-html="getDefinition" />
	</div>
</template>

<script>

	import TermControls from '@/components/glossary/TermControls.vue';

	export default {
		components: {
			TermControls
		},
		props: {
			term: {
				type: Object,
				default: undefined
			}
		},
		data: () => {
			return {

			};
		},
		computed: {
			getId () {
				return this.term.slug;
			},
			getAnchor () {
				if (this.term) {
					return {
						hash: `#${this.getId}`
					};
				}
				return {};
			},
			getName () {
				return this.term?.name;
			},
			getDefinition () {
				return this.term?.definition;
			},
			getFocuses () {
				return this.term?.focuses || [];
			},
			getIsAdmin () {
				return this.$store.getters['auth/getIsAdmin'];
			}
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>

	.term-item {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 32px;

		.term-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.term-name {
				@include font(28px, $c-darkest-navy, 500, 28px);
				position: relative;

				&:after {
					@include font(24px, $c-gray-d3, 400, 26px);
					content: '#';
					position: absolute;
					left: calc(100% + 4px);
					top: 2px;
					display: none;
				}

				&:hover {
					&:after {
						display: block;
					}
				}
			}
		}

		::v-deep .term-definition {
			@include font(16px, $c-darkest-navy, 400, 22px);
			@include rich-text-content;
			@include rich-text-suits;
		}
	}

</style>
