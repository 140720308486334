<template>
	<div class="filters">
		<div class="filters__title">
			<div class="filters__title--text">
				<icon icon="v2-filter" colour="transparent" />
				Filters
			</div>
			<div class="filters__title--btn" @click="clearAll">
				clear all
				<icon icon="v2-cross" colour="transparent" />
			</div>
		</div>

		<v2-search-input v-model="searchValue" />

		<v2-checkbox-item
			class="beginner-checkbox"
			:value="beginner"
			:selected="beginner"
			@change="$emit('toggle-beginner')">
			Beginner
		</v2-checkbox-item>

		<div class="filters__list">
			<filter-wrapper
				v-for="filter in filters"
				:key="filter.label"
				:filter="filter"
				@update-filter="onFilter"
			/>
		</div>

		<div class="filters-popup">

			<div class="filters-popup__title" v-if="!isPopUp" @click="isPopUp = true">
				<div class="filters-popup__title--text">
					<icon icon="v2-filter" colour="transparent" />
					Filters
				</div>
				<div class="filters-popup__title--btn" @click.stop="clearAll">
					clear all
					<icon icon="v2-cross" colour="transparent" />
				</div>
			</div>

			<div v-if="isPopUp" @click="isPopUp = false" class="filters-popup__menu-wrapper" />
			<div v-if="isPopUp" class="filters-popup__menu">
				<div class="filters-popup__title">
					<div class="filters-popup__title--text">
						<icon icon="v2-filter" colour="transparent" />
						Filters
					</div>
				</div>

				<v2-search-input v-model="searchValue" />

				<div class="filters-popup__menu__list">
					<filter-wrapper
						v-for="filter in filters"
						:key="filter.label"
						:filter="filter"
					/>
				</div>

				<V2Button @click="onSubmit">
					Show results
				</V2Button>
			</div>
		</div>
	</div>
</template>

<script>
	import Icon           from '@/components/ui/Icon';
	import V2Button       from '@/components/v2/ui/V2Button';
	import FilterWrapper  from '@/components/glossary/FilterWrapper';
	import V2SearchInput  from '@/components/v2/ui/V2SearchInput';
	import V2CheckboxItem from '@/components/v2/ui/checkbox/V2CheckboxItem';

	export default {
		components: {
			Icon,
			FilterWrapper,
			V2SearchInput,
			V2Button,
			V2CheckboxItem
		},

		props: {
			filters: {
				type: Array,
				required: true
			},
			search: {
				type: String,
				required: true
			},
			beginner: {
				type: Boolean,
				required: true
			}
		},

		emits: ['update-search, update-filter', 'toggle-beginner'],

		data () {
			return {
				searchValue: '',
				isPopUp: false
			};
		},

		watch: {
			searchValue: {
				handler (newValue, oldValue) {
          if (this.isPopUp) {
            return;
          }

          if (newValue === oldValue) {
            return;
          }
          this.$emit('update-search', newValue);
				},
				deep: true
			}
		},

		methods: {
			clearAll () {
				this.filters.forEach(item => {
					item.selected = [];
				});

				this.searchValue = '';

				this.$emit('update-search', this.searchValue);
			},

			onFilter () {
				this.$emit('update-filter');
			},

			onSubmit () {
				this.isPopUp = false;
				this.$emit('update-search', this.searchValue);
			}
		}
	};
</script>

<style lang="scss" scoped>

.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1279px) {
      display: none;
    }

    &--text {
      display: flex;
      align-items: center;
      @include font(18px, $c-darkest-navy, 700, 23px);
      gap: 6px;;
    }

    &--btn {
      display: flex;
      align-items: center;
      @include font(16px, $c-darkest-navy, 600, 20px);
      gap: 6px;
      background: $c-gray-l;
      padding: 4px 8px;
      border-radius: 2px;
      cursor: pointer;
    }
  }

  .search-input {
    @media (max-width: 1279px) {
      display: none;
    }
  }

	.beginner-checkbox {
		margin-bottom: 0;
	}

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: 1279px) {
      display: none;
    }
  }

  &-popup {
    display: none;

    @media (max-width: 1279px) {
      display: flex;
    }

    .search-input {
      display: flex;
      margin-bottom: 12px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      width: 100%;

      &--text {
        display: flex;
        align-items: center;
        @include font(18px, $c-darkest-navy, 700, 23px);
        gap: 6px;;
      }

      &--btn {
        display: flex;
        align-items: center;
        @include font(16px, $c-darkest-navy, 600, 20px);
        gap: 6px;
        background: $c-gray-l;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }

    &__menu-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 200;
      background: $c-dark-navy;
      opacity: 0.2;
    }

    &__menu {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 80vh;
      z-index: 300;
      padding: 24px 20px;
      border-radius: 12px 12px 0 0;
      background: $c-bg-white;
      overflow-y: auto;

      &__list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;
      }
    }

  }
}

</style>
