<template>
	<div class="back-to-top-button" @click="handleClick">
		<icon icon="caret-up" colour="dark-navy" />
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon.vue';
	import { scrollTop } from '@/helpers';

	export default {
		components: {
			Icon
		},
		props: {
			externalHandler: {
				type: Boolean,
				default: false
			}
		},
		data: () => {
			return {

			};
		},
		computed: {
		},
		methods: {
			handleClick () {
				if (this.externalHandler) {
					return;
				}

				scrollTop();
			}
		}
	};

</script>

<style lang="scss" scoped>

	.back-to-top-button {
		position: fixed;
		right: 70px;
		bottom: 70px;
		z-index: 1;
		cursor: pointer;
		border-radius: 4px;

		svg {
			width: 70px;
			height: 70px;
			background-color: $c-brand-white;
		}

		@media (max-width: 1279px) {
			right: 0;
			bottom: 59px;
		}
	}

</style>
